import { DynamicFieldsEnum } from 'utils/enums';

export const productSetting: SetupPropType[] = [
  // {
  //   id: 1,
  //   title: 'Title',
  //   description:
  //     'Add a general title for your story list. It will be displayed as header for your stories.',
  //   fields: [
  //     {
  //       id: 22,
  //       type: DynamicFieldsEnum.Input,
  //       value: '',
  //       name: 'title',
  //       label: 'Title',
  //       // maxLength: 50,
  //     },
  //   ],
  // },
  // {
  //   id: 22,
  //   title: 'Duration',
  //   description:
  //     'Add general duration for your stories. It will be valid for all displayed stories unless you make an individual setting for each story. This can be done from the Storys Settings.',
  //   fields: [
  //     {
  //       id: 2432,
  //       type: DynamicFieldsEnum.Select,
  //       value: '1',
  //       name: 'duration',
  //       label: 'Duration (days)',
  //       itemsArray: [
  //         {
  //           label: '1',
  //           value: '1',
  //         },
  //         {
  //           label: '2',
  //           value: '2',
  //         },
  //         {
  //           label: '3',
  //           value: '3',
  //         },
  //         {
  //           label: '4',
  //           value: '4',
  //         },
  //         {
  //           label: '5',
  //           value: '5',
  //         },
  //         {
  //           label: '6',
  //           value: '6',
  //         },
  //         {
  //           label: '7',
  //           value: '7',
  //         },
  //         {
  //           label: '8',
  //           value: '8',
  //         },
  //         {
  //           label: '9',
  //           value: '9',
  //         },
  //         {
  //           label: '10',
  //           value: '10',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 3,
    title: 'Sliding period',
    description:
      'Choose the sliding period for which each story will be displayed. Note that for a Video story this setting cannot be applied. In this case the sliding period is determined by the length of the uploaded video.',
    fields: [
      {
        id: 2232,
        type: DynamicFieldsEnum.Select,
        value: '20',
        name: 'sliding_period',
        label: 'Sliding period (seconds)',
        itemsArray: [
          {
            label: '5',
            value: '5',
          },
          {
            label: '10',
            value: '10',
          },
          {
            label: '15',
            value: '15',
          },
          {
            label: '20',
            value: '20',
          },
          {
            label: '25',
            value: '25',
          },
          {
            label: '30',
            value: '30',
          },
        ],
      },
      {
        id: 232,
        type: DynamicFieldsEnum.Checkbox,
        value: false,
        name: 'enable_pausing',
        label: 'Enable pausing',
      },
    ],
  },
  {
    id: 4,
    title: 'Mute videos',
    description: 'Choose whether to keep the sound of your videos or to mute it.',
    fields: [
      {
        id: 232,
        type: DynamicFieldsEnum.Checkbox,
        value: false,
        name: 'mute_videos',
        label: 'Enable',
      },
    ],
  },
  {
    id: 5,
    title: 'Visible stories limit',
    description:
      'Choose the sliding period for which each story will be displayed. Note that for a Video story this setting cannot be applied. In this case the sliding period is determined by the length of the uploaded video.',
    fields: [
      {
        id: 22,
        type: DynamicFieldsEnum.Select,
        value: '4',
        name: 'visible_stories_limit',
        label: 'Visible stories limit',
        itemsArray: [
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4',
            value: '4',
          },
          {
            label: '5',
            value: '5',
          },
          {
            label: '6',
            value: '6',
          },
          {
            label: '7',
            value: '7',
          },
          {
            label: '8',
            value: '8',
          },
          {
            label: '9',
            value: '9',
          },
          {
            label: '10',
            value: '10',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: 'Badge Position',
    description:
      'Change the badge position on the screen. Choose one of six positions that will fit best to your theme. Also stick the badge on the screen or let it move while scrolling.',
    fields: [
      {
        id: 22,
        type: DynamicFieldsEnum.Select,
        value: 'Top Right',
        name: 'badge_position',
        label: 'Badge position',
        itemsArray: [
          {
            label: 'Top Right',
            value: 'Top Right',
          },
          {
            label: 'Top Left',
            value: 'Top Left',
          },
          {
            label: 'Bottom Left',
            value: 'Bottom Left',
          },
          {
            label: 'Bottom Right',
            value: 'Bottom Right',
          },
        ],
      },
      {
        id: 232,
        type: DynamicFieldsEnum.Checkbox,
        value: false,
        name: 'sticky_badge',
        label: 'Sticky badge',
      },
    ],
  },
  {
    id: 7,
    title: 'Badge',
    description: 'Select the shape and colors for the background, border and text of the badge',
    fields: [
      {
        id: 22,
        type: DynamicFieldsEnum.BadgeShape,
        value: 'circle',
        name: 'badge_form',
        itemsArray: [
          {
            label: 'Circle',
            value: 'circle',
            tooltipText: 'Set badge shape to circle',
          },
          {
            label: 'Square',
            value: 'square',
            tooltipText: 'Set badge shape to square',
          },
        ],
      },
      {
        id: 232,
        type: DynamicFieldsEnum.BadgeColors,
        name: 'colors',
        label: 'Badge',
        value: 'yellow&blue&red',
        itemsArray: [
          {
            label: 'Background',
            value: 'background',
            tooltipText: 'Set badge background color ',
          },
          {
            label: 'Border',
            value: 'border',
            tooltipText: 'Set badge border color ',
          },
          {
            label: 'Text',
            value: 'text',
            tooltipText: 'Set badge text color ',
          },
        ],
      },
    ],
  },
  // {
  //   id: 8,
  //   title: 'story color',
  //   description: 'Select the shape and colors for the background, border and text of the badge',
  //   fields: [
  //     {
  //       id: 232,
  //       type: DynamicFieldsEnum.StoryColor,
  //       name: 'story_color',
  //       label: 'Badge',
  //       value: '#4B4DC8',
  //       itemsArray: [
  //         {
  //           label: 'Border',
  //           value: 'border',
  //           tooltipText: 'Set story color ',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   title: 'story color',
  //   description:
  //   'Select the shape and colors for the background, border and text of the badge',
  //   fields: [
  //     {
  //       id: 22,
  //       type: DynamicFieldsEnum.StoryColor,
  //       value: 'circle',
  //       name: 'story_color',
  //       itemsArray: [
  //         {
  //           label: 'Border',
  //           value: 'Border',
  //           tooltipText: 'Set story color',
  //         },
  //       ]
  //     }
  //   ]
  // },
];
